import React from 'react'
import {Icon} from 'antd'
import {Link} from 'gatsby'

const Logo = () => (
    <Link className="logo" to="/">
        <Icon type="mail" theme="twoTone" style={{ fontSize: '35px'}} />
        <h1>Emaildrop</h1>
    </Link>
)

export default Logo;