import React from 'react';
import * as moment from 'moment';
import {Query, Mutation} from 'react-apollo';
import gql from 'graphql-tag'
import {Icon, Spin} from 'antd';
import { CSSTransitionGroup } from 'react-transition-group'
import {Link} from 'gatsby'
import AccountManagement from '../components/AccountManagement'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PropTypes from 'prop-types'

class Home extends React.Component{
  state = {
    email: null,
    username: null,
  }
  handleEmailAccountForParent = () => {
    this.setState({
      email: localStorage.getItem('email'),
      username: localStorage.getItem('username'),
    })
  }
  render(){
    return (
      <Layout>
        <SEO title="Home" description="Want to fight email abuse and spam?
    Use Emaildrop to create free and unlimited disposable email addresses."/>
        <main>
            <div className="main-wrapper">
            <AccountManagement
            handleEmailAccountForParent={this.handleEmailAccountForParent.bind(this)}
            />
            {this.state.email &&
              <div className="inbox-management">
                <h1 className="title">Inbox</h1>
                <InboxManagementWithData username={this.state.username}/>
              </div>
            }
            </div>
        </main>
      </Layout>
    )
  }
}

const GET_EMAILS = gql`
query Email($username: String!){
    emails(username: $username){
      token,
      sender, 
      subject,
      body_plain,
      timestamp,
    }
}`

const GET_NEW_EMAILS = gql`
subscription emailAdded($username: String!){
  emailAdded(username: $username){
    token,
    sender, 
    subject,
    body_plain,
    timestamp,
  }
}`

const InboxManagementWithData = ({username}) => (
  <Query
  query={GET_EMAILS}
  variables={{username}}>
  {({subscribeToMore, ...result}) => 
      <InboxManagement 
      username={username}
      {...result}
      subscribeToNewEmails={account => 
        subscribeToMore({
          document: GET_NEW_EMAILS,
          variables: {username: account},
          updateQuery: (prev, { subscriptionData}) => {
            if (!subscriptionData.data) return prev;
            const newEmail = subscriptionData.data.emailAdded;
            return Object.assign({}, prev, {
              emails: [newEmail, ...prev.emails]
            });
          }
        })
      }
      
      />
  }
  </Query>
)

InboxManagementWithData.protoTypes = {
  username: PropTypes.string
}

class InboxManagement extends React.Component{
  componentDidMount = () => {
    this.unsubscribe = this.props.subscribeToNewEmails(this.props.username)
  }
  componentWillReceiveProps = ({username}) => {
    if (this.props.username !== username){
      if (this.unsubscribe){
        this.unsubscribe()
      }
      this.unsubscribe = this.props.subscribeToNewEmails(username)
    }
  }
  componentWillMount = () => {
    if (this.unsubscribe){
      this.unsubscribe()
    }
  }
  render(){
    let {loading, error, data} = this.props
    if (loading) return (
      <div className="inbox-loading">
        <Spin size="large"/>
      </div>
    )

    if (error) return (
      <div className="inbox-empty">
        <Icon type="inbox" style={{ fontSize: '100px'}}/>
        <h3>Error loading inbox :(</h3>
      </div>
    )

    if (data.emails && data.emails.length === 0) return (
      <div className="inbox-empty">
        <Icon type="inbox" style={{ fontSize: '100px'}}/>
        <h3>Inbox empty</h3>
      </div>
    )
    return (
      <div>
        <CSSTransitionGroup
        transitionName="email"
        transitionEnter={true}
        transitionEnterTimeout={300}
        transitionLeave={false}>
          {data.emails.map(email => 
            <Email email={email} username={this.props.username} key={email.token}/>
          )}
        </CSSTransitionGroup>
      </div>
    )
  }
}

const DELETE_EMAIL = gql`
mutation DeleteEmail($username: String!, $token: String!){
  email(username: $username, token: $token){
    to,
    token
  }
}`

const Email = ({email, username}) => (
  <div className="email fade">
    <div className="meta">
      <Link to={`/email/?token=${email.token}`} className="link">
        <div className="from">{email.sender}</div>
        <div className="subject">{email.subject}</div>
        <div className="content-preview">{email.body_plain}</div>
        <div className="date">{moment(new Date(email.timestamp * 1000)).fromNow()}</div>
      </Link>
      <div className="options">
        <Mutation
         mutation={DELETE_EMAIL}
         update={(cache, { data : {email : {token}}}) => {
           const {emails} = cache.readQuery({query: GET_EMAILS, variables: {username}});
           const recs = emails.filter(email => email.token !== token)
           cache.writeQuery({
             query: GET_EMAILS,
             variables: {username},
             data: {emails: recs},
           })
         }}
        >
        {(del, { data }) => (
          <button className="delete button-plain" onClick={() => del({variables: {username, token: email.token}})}>
            <Icon type="delete"/> Delete
          </button>
        )}
        </Mutation>
      </div>
    </div>
  </div>
)

Email.propTypes = {
  email: PropTypes.object,
  username: PropTypes.string
}

export default Home