import React from 'react'
import {StaticQuery, graphql, Link} from 'gatsby'
import PropTypes from 'prop-types'

const Footer = () => (
    <StaticQuery 
     query={graphql`
     {
        allFooterJson {
          edges {
            node {
              title,
              links {
                name
                urlName
              }
            }
          }
        }
      }
     `}
     render={data => (
        <footer>
            <div className="footer-wrapper">
                    {data.allFooterJson.edges.map((edge) => 
                            <FooterSection key = {edge.node.title} node={edge.node}/>
                        )}
            </div>
            <div className="copyright">
                &copy; 2019 - {new Date().getFullYear()} Emaildrop
            </div>
        </footer>
     )}
    />
)

const FooterSection = ({node}) => (
    <div className="footer-section">
        <h4 className="footer-section-title">{node.title}</h4>
        <ul>
            {node.title === 'Other' &&
                <li>
                    <a href="mailto: admin@api.emaildrop.io">Contact</a>
                </li>
            }
            {node.links.map(link => (
                <li key={link.name}>
                    <Link to={`/${link.urlName}`}>{link.name}</Link>
                </li>
            ))}
        </ul>
    </div>
)

FooterSection.propTypes = {
    node: PropTypes.object
}

export default Footer