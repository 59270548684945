import React from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Spin, Tag, Input} from 'antd'
import { ApolloConsumer } from 'react-apollo'
import gql from "graphql-tag"

const VALIDATE_USERNAME = gql`
    query ValidateUsername($username: String!){
        validateUsername(username: $username){
        valid
        }
    }
`

class AccountManagement extends React.Component{
    state = {
        copyStatus: 'Copy',
        inputVisible: false,
        inputValue: '',
        email: null,
        username: null,
        showusernameError: false
    }
    componentDidMount = () => {
        if (localStorage.getItem('email') === null || localStorage.getItem('username') === null){
            this.generateNewEmailAddress()
        } else {
            this.handleEmailAccountForChild()
        }
    }
    generateNewEmailAddress= () => {
        var username = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 8; i++){
            username += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        this.handleEmailAccountForChild(username)
    }
    handleEmailAccountForChild = (username) => {
        if (typeof username !== 'undefined'){
            localStorage.setItem('email', username + "@emaildrop.io")
            localStorage.setItem('username', username)
        }
        this.setState({
            email: localStorage.getItem('email'),
            username: localStorage.getItem('username')
        }, this.props.handleEmailAccountForParent())
        
    }
    showInput = (e) => {
        e.preventDefault();
        this.setState({
            inputVisible: true
        }, () => this.input.focus())
    }
    handleInputChange = (e) => {
        this.setState({ inputValue: e.target.value });
    }
    handleInputConfirm = () => {
        let username = this.state.inputValue
        this.setState({
            inputValue: '',
            inputVisible: false,
            showusernameError: false
        }, this.handleEmailAccountForChild(username))
    }
    saveInputRef = input => this.input = input
    render(){
        return (
        <div className="email-management">
            <div className="email-account">
            <div className="your-account">
                <div className="account-wrapper">
                <p>Your Account</p>
                <ApolloConsumer>
                    {client => (
                        <div className="email-address">
                                {this.state.email && !this.state.inputVisible &&
                                    <code><Tag>{this.state.username}</Tag>@emaildrop.io</code>
                                }
                                {!this.state.email && !this.state.inputVisible &&
                                    <Spin size="small"/>
                                }
                                {this.state.email && this.state.inputVisible &&
                                    <code>
                                        <Input 
                                        ref={this.saveInputRef}
                                        type="text"
                                        size="small"
                                        style={{ width: 78}}
                                        value={this.state.inputValue}
                                        onChange={this.handleInputChange}
                                        onPressEnter={() => this.input.blur()}
                                        onBlur={async () => {
                                            client.query({
                                                query: VALIDATE_USERNAME,
                                                variables: { username: this.state.inputValue }
                                            }).then(({data}) => {
                                                this.handleInputConfirm()
                                            }).catch(({ graphQLErrors }) => {
                                                this.setState({
                                                    showusernameError: true
                                                })
                                            })
                                        }}
                                        />@emaildrop.io
                                    </code>
                                }
                        </div>
                    )}
                </ApolloConsumer>
                </div>
                {this.state.showusernameError &&
                    <p id="username-error">Invalid Email Address</p>
                }
                <div className="account-options">
                <CopyToClipboard 
                    text={this.state.email} 
                    onCopy={() => {
                        this.setState({
                            copyStatus: 'Copied'
                        }, () => setTimeout(() => this.setState({copyStatus: 'Copy'}), 3000))
                    }}>
                        <button className="account-option button-plain">
                            {this.state.copyStatus}
                        </button>
                </CopyToClipboard>
                {!this.state.inputVisible && 
                    <button className="account-option button-plain" onClick={this.generateNewEmailAddress}>
                        Random
                    </button>
                }
                {!this.state.inputVisible &&
                <button className="account-option button-plain" onClick={this.showInput}>
                    Custom
                </button>
                }
                {this.state.inputVisible &&
                <button className="account-option button-plain" onClick={() => this.input.blur()}>
                    Save
                </button>
                }
                
                </div>
            </div>
            </div>
        </div>
        )
    }
}

export default AccountManagement